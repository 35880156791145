import { Link } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useRealmApp } from "./realm_app";
import OnboardingNavbar from "./onboarding_navbar";
import { Footer } from "./footer";
import "../index.css";

export default ({ home_uri }) => {
  const realmApp = useRealmApp();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");

  async function handleSendPasswordResetEmail(event) {
    event.preventDefault();
    setLoading("Sending password reset email...");
    try {
      await realmApp.app.emailPasswordAuth.sendResetPasswordEmail(email);
      setLoading(
        "A password reset email has been sent to your inbox. " +
          "Please click the link inside to proceed."
      );
    } catch (err) {
      console.log(err);
      console.log(JSON.stringify(err));
      setLoading(`error: ${err.error}`);
    }
  }

  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <div className="page-content-center">
          <h3>Forgot Password</h3>
        </div>
        <div className="page-content-center">
          <div className="page-content page-content-small">
            <br />
            <form
              onSubmit={handleSendPasswordResetEmail}
              className="page-content page-content-small"
            >
              <div>
                <strong>EMAIL</strong>
                <br />
                <input
                  type="text"
                  style={{ display: "table-cell", width: "100%" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <br />
              <Button className="light-green-button btn-block" type="submit">
                Send a Password Reset Email
              </Button>
            </form>
          </div>
        </div>
        <br />
        <div className="page-content-center">
          <Link to={home_uri}>Go home</Link>
        </div>
        <br />
        <div className="page-content-center">{loading}</div>
      </div>
      <Footer />
    </>
  );
};
