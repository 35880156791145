import React from "react";
import {
  RealmAppProvider,
  APP_ID,
  OnboardingRedirector,
} from "../../components/realm_app";
import ForgotPasswordComponent from "../../components/forgot_password";
import "../../index.css";

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <ForgotPasswordComponent home_uri="/member/login/" />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
